.about-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    flex-wrap: wrap; /* Permet de passer à une vue en colonne sur petits écrans */
}

.about-text {
    max-width: 50%;
    padding-right: 30px;
}

.quentum {
    font-size: 36px;
    position: relative;
    right: 15%;
    font-weight: bold;
    margin-bottom: 20px;
    
}

.about-text h3 {
    color: #007bff;
    margin-bottom: 10px;
}

.about-text p {
    margin-bottom: 15px;
    line-height: 1.6;
}

.learn-more {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.learn-more:hover {
    background-color: #0056b3;
}

.about-image {
    max-width: 45%; /* Réduit la taille de l'image */
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* Media queries pour écrans de taille moyenne */
@media (max-width: 1024px) {
    .about-text {
        max-width: 100%;
        padding-right: 0;
        text-align: center;
    }

    .about-image {
        max-width: 100%;
        margin-top: 20px;
    }
}

/* Media queries pour petits écrans (tablettes et téléphones) */
@media (max-width: 768px) {
    .about-container {
        flex-direction: column; /* Passe en colonne pour petits écrans */
        padding: 20px;
    }

    .about-text {
        max-width: 100%;
        text-align: center;
        padding-right: 0;
    }

    .about-image {
        max-width: 100%;
        margin-top: 20px;
    }
    .quentum{position: relative;
        left: 2%;
    
    }
}

@media (max-width: 480px) {
    .about-text h1 {
        font-size: 28px;
    }

    .learn-more {
        font-size: 14px;
        padding: 8px 16px;
    }
}
