/* Conteneur principal pour le formulaire */
form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Titres */
h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

/* Champs d'entrée */
input[type="text"], input[type="tel"], select, input[type="file"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

/* Style pour les boutons */
button {
  width: 100%;
  padding: 12px;
  background-color: #003366; /* Couleur bleu foncé */
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 0;
}

/* Changement de couleur au survol des boutons */
button:hover {
  background-color: #00509e; /* Bleu légèrement plus clair au survol */
}

/* Message de disponibilité du domaine */
p {
  font-size: 14px;
  color: #333;
  padding: 5px;
  text-align: center;
}

/* Style pour le label de la devise */
label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
  display: block;
}

/* Changement de bordure au focus pour les champs */
input:focus, select:focus {
  border-color: #003366; /* Bleu foncé pour indiquer le focus */
  outline: none;
}

/* Style de la disponibilité du domaine */
button[disabled] {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Responsive design */
@media (max-width: 768px) {
  form {
    padding: 15px;
  }

  button {
    font-size: 14px;
    padding: 10px;
  }

  input[type="text"], input[type="tel"], select, input[type="file"] {
    font-size: 14px;
    padding: 8px;
  }
}
