/* Conteneur principal */
.admin-service-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  /* Style des cartes */
  .service-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin: 10px 0;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  /* Titre du service */
  .service-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  /* Image du service */
  .service-image {
    max-width: 100%;
    height: auto;
    margin: 10px 0;
    border-radius: 8px;
  }
  
  /* Boutons de statut */
  .status-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 15px;
  }
  
  .btn-status {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    font-size: 1rem;
  }
  
  .btn-status:hover {
    background-color: #0056b3;
  }
  
  /* Pour les petits écrans (mobile) */
  @media (max-width: 768px) {
    .service-card {
      padding: 10px;
    }
  
    .service-card h3 {
      font-size: 1.3rem;
    }
  
    .btn-status {
      font-size: 0.9rem;
      padding: 8px 12px;
    }
  
    .status-buttons {
      justify-content: center;
    }
  }
  
  /* Pour les très petits écrans (moins de 480px) */
  @media (max-width: 480px) {
    .admin-service-container {
      padding: 10px;
    }
  
    .service-card {
      margin: 5px 0;
    }
  
    .service-card h3 {
      font-size: 1.2rem;
    }
  
    .btn-status {
      font-size: 0.8rem;
      padding: 6px 10px;
    }
  }
  