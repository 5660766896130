/* Style général de la barre de navigation */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 100;
}

/* Logo */
.navbar-logo img {
    height: 45px;
    transition: transform 0.3s ease;
}


.navbar-logo img:hover {
    transform: scale(1.1);
}

/* Menu Desktop */
.navbar-menu {
    display: flex;
    gap: 30px;
    list-style: none;
}

.navbar-menu li a {
    text-decoration: none;
    color: #333333;
    font-weight: 600;
    font-size: 1rem;
    transition: color 0.3s ease;
}

.navbar-menu li a:hover {
    color: #007BFF;
}

/* Espace client */
.navbar-client a, .logout-button {
    text-decoration: none;
    color: #333333;
    font-weight: 600;
    padding: 8px 15px;
    border: 1px solid #007BFF;
    border-radius: 5px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.logout-button {
    background: transparent;
    cursor: pointer;
    position: relative;
    bottom: 20%
}

.navbar-client a:hover, .logout-button:hover {
    background-color: #007BFF;
    color: white;
    border-color: #0056b3;
}

/* Icône de menu mobile (hamburger) */
.mobile-menu-icon {
    display: none;
    background: none;
    border: none;
    font-size: 28px;
    cursor: pointer;
    color: #007BFF;
}

/* Responsive */
@media (max-width: 768px) {
    .navbar-menu, .navbar-client {
        display: none;
    }
    
    .mobile-menu-icon {
        display: block;
    }
    
    /* Menu mobile */
    .navbar-menu-mobile {
        position: absolute;
        top: 70px;
        left: 0;
        width: 100%;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 20px;
        gap: 20px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        transform: translateX(-100%);
        transition: transform 0.3s ease;
        z-index: 99;
    }

    .navbar-menu-mobile.active {
        transform: translateX(0);
    }
    
    .navbar-menu-mobile li a {
        text-decoration: none;
        color: #333333;
        font-weight: bold;
        padding: 10px;
        display: block;
        transition: color 0.3s ease;
    }

    .navbar-menu-mobile li a:hover {
        color: #007BFF;
    }

    /* Espace Client et Déconnexion dans le menu mobile */
    .navbar-client-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .navbar-client-mobile .client-button,
    .navbar-client-mobile .logout-button {
        width: 100%;
        padding: 12px;
        margin-bottom: 10px;
        border: 1px solid #007BFF;
        border-radius: 5px;
        text-align: center;
        text-decoration: none;
        font-weight: bold;
        color: #333333;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    .navbar-client-mobile .logout-button:hover,
    .navbar-client-mobile .client-button:hover {
        background-color: #007BFF;
        color: white;
        border-color: #0056b3;
    }
}
