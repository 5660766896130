/* Style for the container */
.partenaires-container {
    text-align: center;
    padding: 20px;
}

/* Style for the heading */
.partenaires-container h3 {
    font-size: 1.8em;
    margin-bottom: 20px;
    background-color: #e0e8f9;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
}

/* Style for the logos */
.logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 20px;
}

/* Logo images styling */
.logos img {
    max-width: 150px;
    height: auto;
    object-fit: contain;
}

/* Responsive design */
@media (max-width: 768px) {
    .logos {
        gap: 20px;
    }
    
    .logos img {
        max-width: 120px;
    }
}

@media (max-width: 480px) {
    .logos img {
        max-width: 100px;
    }
}
