.adminLo {
    padding: 20px;
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f7f8fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.adminLo h2 {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
}

.client-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.client-item {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    transition: box-shadow 0.3s;
}

.client-item:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.client-item h3 {
    font-size: 20px;
    color: #333;
    margin: 0 0 5px;
}

.client-item p {
    font-size: 16px;
    color: #555;
    margin: 5px 0;
}

.client-item p:last-child {
    margin-bottom: 10px;
}

select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    transition: border-color 0.3s;
}

select:focus {
    border-color: #0056b3;
    outline: none;
}

select option {
    color: #333;
    font-size: 16px;
}

button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #ffffff;
    background-color: #0056b3;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center;
}

button:hover {
    background-color: #004494;
}

@media (max-width: 600px) {
    .adminLo {
        padding: 15px;
    }

    .client-item {
        padding: 10px;
    }

    select {
        font-size: 14px;
    }

    button {
        width: 100%;
        padding: 10px;
    }
}
