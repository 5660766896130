/* Style général pour le conteneur d'administration */
.admin-content {
    padding: 20px;
    border: 1px solid #eaeaea;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

/* Style pour le menu de navigation */
nav {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

/* Style pour les boutons de navigation */
nav button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

/* Effet hover sur les boutons */
nav button:hover {
    background-color: #0056b3;
}

/* Style pour le bouton actif */
nav button.active {
    background-color: #0056b3;
    cursor: default;
}
