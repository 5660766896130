/* Styles de base */
.service-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
    gap: 20px;
}

.service-card {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.service-card:hover {
    transform: translateY(-5px);
}

.service-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
}

.service-description {
    font-size: 1em;
    margin-bottom: 15px;
    color: #666;
    line-height: 1.5;
}

.service-prices {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.local-price, .international-price {
    font-weight: bold;
    color: #007BFF;
}

.btn {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    text-align: center;
}

.btn:hover {
    background-color: #0056b3;
}

/* Styles réactifs */
@media (max-width: 768px) {
    .service-container {
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 769px) and (max-width: 1200px) {
    .service-card {
        width: 45%;
    }
}

@media (min-width: 1201px) {
    .service-card {
        width: 30%;
    }
}
