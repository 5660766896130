.form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    color: #333;
}

form label {
    display: block;
    font-weight: bold;
    margin-top: 1rem;
    color: #555;
}

form input,
form textarea,
form select {
    width: 100%;
    padding: 0.75rem;
    margin-top: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

form textarea {
    resize: vertical;
}

.submit-btn {
    display: block;
    width: 100%;
    padding: 0.75rem;
    margin-top: 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: #0056b3;
}

.error-message {
    color: #d9534f;
    font-size: 0.9rem;
    margin-top: 0.5rem;
}
