.banner{
    width: 100%;
    height: 550px;
}
.copy{
    text-align:center
}
.name-affiche{
    text-align: center;
    color: #2c3e50; /* Bleu foncé élégant */



}