.logiciel-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .intro-section {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .intro-section h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .intro-section p {
    font-size: 1.2rem;
    color: #555;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .features-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-bottom: 3rem;
  }
  
  .feature-card {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    padding: 1.5rem;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .feature-card .emoji {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .feature-card h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .feature-card p {
    font-size: 1rem;
    color: #666;
  }
  
  .offers-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  
  .offer-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    padding: 1.5rem;
    text-align: center;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .offer-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .offer-card h3 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .offer-card p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  .offer-card p:last-child {
    font-weight: bold;
    color: #333;
  }
  