.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 10vh;
    padding: 20px;
    text-align: center;
    background-color: #f4f4f4;
    margin-bottom: 40px;
}

.hero h1 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.hero input {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.hero button {
    width: 100%;
    max-width: 200px;
    padding: 10px;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.hero button:hover {
    background-color: #0056b3;
}

.hero p {
    font-size: 1.2rem;
    margin-top: 20px;
}

/* Responsive styles */
@media (min-width: 768px) {
    .hero h1 {
        font-size: 2.5rem;
    }

    .hero input {
        font-size: 1.2rem;
    }

    .hero button {
        font-size: 1.2rem;
    }

    .hero p {
        font-size: 1.5rem;
    }
}
