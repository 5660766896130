/* Global styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

/* Container for offers */
.offers-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

/* Each offer card */
.offer-card {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.offer-card:hover {
    transform: translateY(-10px);
}

/* Title, description, and price */
.offer-card h2 {
    font-size: 1.5em;
    color: #333;
}

.offer-card p {
    color: #666;
    margin: 10px 0;
}

.offer-card .price {
    font-size: 1.2em;
    color: #2ecc71;
    font-weight: bold;
}

/* Button style */
.btn {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #2980b9;
}
.price2{
    color: #fd7e14;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .offers-container {
        padding: 20px;
        grid-template-columns: 1fr; /* Une seule colonne pour les petits écrans */
    }
}

@media (max-width: 480px) {
    .offer-card h2 {
        font-size: 1.2em;
    }

    .offer-card .price {
        font-size: 1em;
    }
}
