.portfolio-container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
}

h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #2c3e50; /* Bleu foncé élégant */

}

.project {
    margin-bottom: 50px;
}

.project h3 {
    font-size: 24px;
    margin-bottom: 10px;
}

.project p {
    font-size: 16px;
    margin-bottom: 20px;
}

.project-images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px;
}

.project-images img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}




.modal {
    display: flex;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8); /* Fond sombre */
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.modal-content {
    max-width: 90%;
    max-height: 90%;
}

.close {
    position: absolute;
    top: 20px;
    right: 35px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
}





@media screen and (max-width: 768px) {
    .project-images {
        grid-template-columns: 1fr;
    }
}
